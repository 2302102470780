<template>
  <b-col cols="12">
    <b-modal
      id="modal-review-channel"
      ref="modal-review-channel"
      size="lg"
      cancel-variant="outline-secondary"
      :ok-title="$t('Pages.Mentor.Button.Save')"
      no-close-on-backdrop
      content-class="shadow"
      :cancel-title="$t('Pages.Mentor.Button.Close')"
      centered
      :title="$t('Pages.Assistant.Title.add_ch_review')"
      @ok="submitReview"
      @cancel="resetReviewForm"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" class="my-1">
            <b-form-group
              :label="$t('Pages.Assistant.Label.user_desc')"
              label-for="user-description"
              label-cols-md="3"
            >
              <b-form-textarea
                id="user-description"
                v-model="reviewUserDescription"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="my-1">
            <b-form-group
              :label="$t('Pages.Assistant.Label.admin_desc')"
              label-for="admin-description"
              label-cols-md="3"
            >
              <b-form-textarea
                id="admin-description"
                v-model="reviewAdminDescription"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div v-if="!file" class="text-center">
              <div class="mb-2">
                <b-img
                  class="rounded-lg"
                  rounded
                  :src="require('@/assets/images/icons/upload-static.png')"
                  height="80"
                />
              </div>
              <b-button variant="info" class="mt-1" @click="selectFile">{{
                $t("Pages.Assistant.Button.select_file")
              }}</b-button>
            </div>
            <div v-else-if="file && !uploading" class="text-center">
              <div class="mb-2">
                <b-img
                  class="rounded-lg"
                  rounded
                  :src="require('@/assets/images/icons/upload-dynamic.gif')"
                  height="80"
                />
              </div>
              <h6 class="">
                {{
                  $t(
                    "Pages.Assistant.Title.success_file_selected"
                  )
                }}...
              </h6>
            </div>
            <div v-else-if="file && uploading" class="text-center">
              <div class="mb-2">
                <b-img
                  class="rounded-lg"
                  rounded
                  :src="require('@/assets/images/icons/upload-dynamic.gif')"
                  height="80"
                />
              </div>
              <div class="">
                <p v-if="progress != 100">
                  {{ $t("Pages.Assistant.Title.Uploaded") }}
                  <span>{{ progress }}%</span>
                </p>
                <p v-else>{{ $t("Pages.Assistant.Title.upload_comp") }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      v-if="infromationChannel.reviewed"
      id="modal-reviewed-channel"
      ref="modal-reviewed-channel"
      size="lg"
      :ok-title="$t('Pages.Assistant.Button.Close')"
      content-class="shadow"
      centered
      :title="$t('Pages.Assistant.Title.show_review')"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" class="my-1">
            <b-form-group
              :label="$t('Pages.Assistant.Label.user_desc')"
              label-for="user-description"
              label-cols-md="3"
            >
              <b-form-textarea
                id="user-description"
                v-model="reviewUserDescription"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="my-1" v-if="userRole === 'Admin'">
            <b-form-group
              :label="$t('Pages.Assistant.Label.admin_desc')"
              label-for="admin-description"
              label-cols-md="3"
            >
              <b-form-textarea
                id="admin-description"
                v-model="reviewAdminDescription"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-center">
              <b-link
                v-if="reviewVideo"
                :to="`https://www.youtube.com/watch?v=${reviewVideo}`"
                target="_blank"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  class="mt-1 mx-1"
                >
                  {{ $t("Pages.Assistant.Button.show_video") }}
                </b-button>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-col lg="12" sm="12">
      <section class="mb-2" style="height: 160px">
        <b-card
          class="profile-header header-card"
          :style="{
            backgroundImage: `url(${infromationChannel.bannerLarge})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '100%',
          }"
          img-top
          alt="cover photo"
          body-class="p-2"
        >
          <!-- profile picture -->
          <div class="position-relative">
            <div class="profile-img-container d-flex align-items-center">
              <div class="profile-img bg-transparent border-0 shadow-none">
                <b-avatar
                  size="100px"
                  :src="infromationChannel.avatar"
                  alt="profile photo"
                />
              </div>
              <!-- profile title -->
              <div class="profile-title m-2">
                <h2 class="text-white">
                  {{ infromationChannel.nickname }}
                </h2>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1"
                  @click="SeeChannel"
                >
                  {{ $t("Pages.Assistant.Button.see_ch") }}
                </b-button>
                <b-button
                  v-if="
                    infromationChannel.mentor && !infromationChannel.reviewed
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mt-1 mx-1"
                  v-b-modal.modal-review-channel
                >
                  {{ $t("Pages.Assistant.Button.save_ch_review") }}
                </b-button>
                <b-button
                  v-if="
                    infromationChannel.mentor && infromationChannel.reviewed
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mt-1 mx-1"
                  v-b-modal.modal-reviewed-channel
                >
                  {{ $t("Pages.Assistant.Button.Reviewed") }}
                </b-button>
              </div>
              <!--/ profile title -->
            </div>
          </div>
          <!--/ profile picture -->
        </b-card>
      </section>
    </b-col>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BForm,
  BButton,
  BCard,
  BFormTextarea,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  name: "InformationChannel",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    BFormGroup,
  },
  props: ["infromationChannel"],
  data() {
    return {
      file_prew: null,
      file: null,
      uploading: false,
      uploaded: 0,
      failedUpload: 0,
      filename: null,
      reviewUserDescription: "",
      reviewAdminDescription: "",
      userRole: "",
      reviewVideo: "",
    };
  },
  computed: {
    progress() {
      return this.file ? Math.floor((this.uploaded * 100) / this.file.size) : 0;
    },
    formData() {
      let formData = new FormData();
      formData.set("video", this.file, `${this.file.name}`);
      formData.set("channel_id", this.infromationChannel.channel_id);
      return formData;
    },
    configMainServer() {
      return {
        method: "POST",
        data: this.formData,
        // url:  `https://api.master.tube/api/channel/studio/videos/upload-file`,
        url: "api/tools/assistant/upload-video",
        timeout: 99999999999999999,
        headers: {
          Authorization: "Bearer " + this.$VueCookies.get("mastertube"),
          "Content-Type": "application/octet-stream",
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.loaded <= this.file.size) {
            this.show = true;
          } else {
            this.$toast.clear();
          }
          this.uploaded = progressEvent.loaded;
        },
      };
    },
  },
  methods: {
    SeeChannel() {
      window.open(
        `https://www.youtube.com/channel/${this.infromationChannel.channel_id}`,
        "_blank"
      );
    },
    updateStatus(id) {
      this.$http
        .get(`/api/mentor/request-channel/done/assistant`, {
          params: {
            id: this.$route.params.uuid,
          },
        })
        .then((response) => {
          this.makeToast(
            this.$t("Base.Alert.Successful"),
            this.$t(
              "Base.Alert.success_change_status"
            ),
            "success"
          );
        });
    },
    uploadMainServer() {
      this.uploading = true;
      this.$http(this.configMainServer)
        .then((response) => {
          if (response.data.status) {
            this.filename = response.data.filename;
          }
        })
        .catch(() => {
          if (this.failedUpload < 7) {
            this.failedUpload += 1;
            this.uploadMainServer();
          } else {
            this.$swal({
              title: this.$t("Base.Alert.Error"),
              text: this.$t(
                "Base.Alert.no_connect_server"
              ),
              icon: "warning",
              confirmButtonText: this.$t("Base.Alert.Understand"),
            });
          }
        });
    },
    createFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.file_prew = reader.result;
      };
      this.file = file;
      setTimeout(() => {
        this.uploadMainServer();
      }, 3000);
    },
    selectFile() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (_) => {
        let files = Array.from(input.files);
        this.createFile(files[0]);
      };
      input.click();
    },
    submitReview(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.file && this.filename != null) {
        let data = {
          uuid: this.$route.params.uuid,
          userNote: this.reviewUserDescription,
          adminNote: this.reviewAdminDescription,
          video: this.filename,
        };
        this.$http
          .post("/api/tools/assistant/channel-review/store", data)
          .then(({ data }) => {
            if (data.status) {
              this.infromationChannel.reviewed = true;
              this.$swal({
                icon: "success",
                title: this.$t("Base.Alert.Successful"),
                text: this.$t(
                  "Base.Alert.success_review_submit"
                ),
              });
            } else {
              this.$swal({
                icon: "error",
                title: this.$t("Base.Alert.Error"),
                text: this.$t(
                  "Base.Alert.unsuccess_review_submit"
                ),
              });
            }
            this.$nextTick(() => {
              this.$bvModal.hide("modal-review-channel");
            });
            this.resetReviewForm();
          });
      }
    },
    resetReviewForm() {
      this.reviewUserDescription = "";
      this.reviewAdminDescription = "";
      this.file = null;
      this.uploading = false;
      this.uploaded = 0;
      this.failedUpload = 0;
      this.filename = null;
    },
    getUserInfo() {
      this.$http.get("/api/user/getUserInfo").then(({ data }) => {
        this.userRole = data.rule;
      });
    },
    getReviewData() {
      this.$http
        .get(`/api/tools/assistant/channel-review/${this.$route.params.uuid}`)
        .then(({ data }) => {
          if (data.status) {
            this.reviewUserDescription = data.analytic.user_description;
            this.reviewAdminDescription = data.analytic.admin_description;
            this.reviewVideo = data.analytic.video;
          } else {
            this.$swal({
              icon: "error",
              title: this.$t("Base.Alert.Error"),
              text: this.$t(
                "Base.Alert.unsuccess_review_submit"
              ),
            });
          }
          this.$nextTick(() => {
            this.$bvModal.hide("modal-review-channel");
          });
          this.resetReviewForm();
        });
    },
  },
  created() {
    if (this.infromationChannel.reviewed) {
      this.getUserInfo();
      this.getReviewData();
    }
  },
  directives: {
    Ripple,
  },
};
</script>

<style></style>
